import { render, staticRenderFns } from "./loader.vue?vue&type=template&id=f2448eba&scoped=true&"
import script from "./loader.vue?vue&type=script&lang=js&"
export * from "./loader.vue?vue&type=script&lang=js&"
import style0 from "./loader.vue?vue&type=style&index=0&id=f2448eba&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f2448eba",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/builds/isc-listen/listen/listen-microfront-v3/isc-authorization/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('f2448eba')) {
      api.createRecord('f2448eba', component.options)
    } else {
      api.reload('f2448eba', component.options)
    }
    module.hot.accept("./loader.vue?vue&type=template&id=f2448eba&scoped=true&", function () {
      api.rerender('f2448eba', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/loader.vue"
export default component.exports