var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-app",
    {
      staticClass: "authorization",
      staticStyle: { "overflow-y": "hidden !important" },
    },
    [_c("div", { staticClass: "main-container" }, [_c("Loader")], 1)]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }